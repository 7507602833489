export default function Tooltip({
  title,
  subtitle,
  lines,
}: Readonly<{
  title?: string;
  subtitle?: string;
  lines?: { title: string; value?: string; color?: string }[];
}>) {
  return (
    <div className="rounded-md border border-secondary text-sm">
      <div className="rounded-t-md border-b border-secondary bg-highlight bg-opacity-80 p-1">
        <h4 className="font-bold">{title}</h4>
        {subtitle && <h5>{subtitle}</h5>}
      </div>
      <div className="grid grid-cols-2 divide-x divide-dotted rounded-b-md bg-black bg-opacity-80">
        <div className="divide-y divide-secondary">
          {lines?.map((item) => (
            <div key={item.title} className="p-1" style={{ color: item.color }}>
              {item.title}
            </div>
          ))}
        </div>
        <div className="divide-y divide-secondary">
          {lines?.map((item) => (
            <div key={item.title} className="p-1">
              {item.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
