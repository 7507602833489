import BillboardJS, { IChart } from "@billboard.js/react";
import bb, {
  ChartOptions,
  area,
  areaLineRange,
  areaStep,
  areaStepRange,
  line,
  step,
  subchart,
} from "billboard.js";
import "billboard.js/dist/billboard.css";
import { timeFormat } from "d3-time-format";
import { useContext, useEffect, useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import isValidNumber from "../../utils/isValidNumber";
import zip from "../../utils/zip";
import { dataContext } from "../DataContextProvider";
import "./graph.css";
import Tooltip from "./Tooltip";
import { GraphDataType, SeriesType } from "./types";

type GraphConfigType = {
  xSearch?: string;
  series?: SeriesType[];
  inspection?: string;
  legend?: {
    display?: boolean;
    position?: "right" | "bottom" | "inset";
  };
  subchart?: {
    disabled?: boolean;
    hideX?: boolean;
    defaultWindowLast?: number;
  };
  tooltip?: {
    disabled?: boolean;
    subtitleSearch?: string;
    precision?: number;
  };
} & (
  | {
      xType?: "timeseries";
      xFormat?: string;
      subchart?: {
        xFormat?: string;
      };
      tooltip?: {
        xFormat?: string;
      };
    }
  | { xType?: "category" | "indexed" | "log" }
);

function getBacktestingGraphData(
  config: GraphConfigType,
  getNumbersArray: (searchString: string) => number[] | undefined
) {
  const data: GraphDataType = {
    series: {},
    columns: [],
    types: {},
    colors: {},
  };

  if (config.xSearch) {
    const rawX = getNumbersArray(config.xSearch);
    if (rawX) {
      if (config.xType === "timeseries")
        data.series.x = rawX.map((time: number) => time * 1000);
      else data.series.x = rawX;
    }
  }

  if (config.series) {
    config.series.forEach((ts, tskey) => {
      const label = ts.label ?? `y-${tskey}`;

      switch (ts.type) {
        case "area-line-range":
        case "area-step-range": {
          const values = zip(
            ...ts.search
              .map((search) => getNumbersArray(search))
              .filter((item): item is number[] => !!item)
          )
            .map((item) => item.filter(isValidNumber))
            .map((item) => item.sort((a, b) => a - b))
            .map((item) => {
              if (item.length >= 3) return item;
              if (item.length >= 1) return [item[0], item[0], item[0]];
              return null;
            });

          data.series[label] = values;
          data.types[label] =
            ts.type === "area-line-range" ? areaLineRange() : areaStepRange();
          if (ts.color) data.colors[label] = ts.color;
          break;
        }

        case "area":
        case "area-step":
        case "line":
        case "step": {
          const search = getNumbersArray(ts.search);
          if (search) data.series[label] = search;

          area();
          areaStep();
          line();
          step();
          data.types[label] = ts.type;

          if (ts.color) data.colors[label] = ts.color;
          break;
        }

        default:
          break;
      }
    });
  }

  return data;
}

export default function BacktestingGraph({
  config,
  onElementSelected,
}: Readonly<{
  config: GraphConfigType;
  onElementSelected: (val: [string, number] | undefined) => void;
}>) {
  const ref = useRef<IChart>(null);
  const { getNumbersArray, getValue } = useContext(dataContext);

  const data = useMemo(
    () => getBacktestingGraphData(config, getNumbersArray),
    [getNumbersArray, config]
  );

  useEffect(() => {
    const chart = ref.current?.instance;
    if (!chart) return;

    chart.load({
      json: data.series as any,
      types: data.types,
      unload: false,
      colors: data.colors,
      resizeAfter: true,
    });

    if (
      !config.subchart?.disabled &&
      config.subchart?.defaultWindowLast &&
      data.series.x
    ) {
      const last = data.series.x[data.series.x.length - 1] as number;

      if (config.xType === "timeseries") {
        chart.subchart([last - config.subchart.defaultWindowLast * 1000, last]);
      } else {
        chart.subchart([
          data.series.x[
            data.series.x.length - config.subchart.defaultWindowLast - 1
          ] as number,
          last,
        ]);
      }
    }
  }, [data, config.subchart?.defaultWindowLast, config.xType]);

  const options = useMemo(
    (): ChartOptions => ({
      area: {
        front: false,
      },
      point: {
        r(d) {
          return (
            config.series?.find((ts) => ts.label === d.id)?.pointRadius ?? 3
          );
        },
      },
      data: {
        json: data.series,
        x: "x",
        types: data.types,
        colors: data.colors,
        onclick(d) {
          if (d.index && config.inspection)
            onElementSelected([config.inspection, d.index]);
        },
      },
      subchart: {
        show: config.subchart?.disabled ? false : subchart(),
        size: { height: 20 },
        axis: {
          x: {
            show: !config.subchart?.hideX,
            tick: {
              format:
                config.xType === "timeseries"
                  ? config.subchart?.xFormat ?? config.xFormat ?? "%d %b %Y" // See https://d3js.org/d3-time-format#locale_format
                  : undefined,
            },
          },
        },
      },
      padding: {
        mode: "fit",
        right: 5,
      },
      legend: {
        show: config.legend?.display ?? true,
        position: config.legend?.position ?? "right",
        hide: config.series?.filter((s) => s.legend?.hide).map((s) => s.label),
        item: {
          onclick(id) {
            const item = config.series?.find((v) => v.label === id);
            if (!item) return;

            // eslint-disable-next-line react/no-this-in-sfc
            const chart = this;

            if (item.legend?.group)
              chart.toggle(
                config.series
                  ?.filter((v) => v.legend?.group === item.legend?.group)
                  .map((v) => v.label)
              );
            else chart.toggle(item.label);
          },
        },
      },
      grid: {
        x: {
          show: true,
        },
        y: {
          show: true,
          ticks: 4,
        },
      },
      axis: {
        x: {
          type: config.xType,
          tick: {
            format:
              config.xType === "timeseries"
                ? config.xFormat ?? "%d %b %Y" // See https://d3js.org/d3-time-format#locale_format
                : undefined,
          },
        },
        y: {
          tick: {
            culling: {
              max: 4,
              lines: false,
            },
          },
        },
      },
      tooltip: {
        linked: false,
        grouped: true,
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          const precision = config.tooltip?.precision ?? 3;
          let title;
          let subtitle;

          if (config.tooltip?.subtitleSearch) {
            const res = getValue(config.tooltip.subtitleSearch);
            subtitle = Array.isArray(res) ? res[d[0]?.index ?? 0] : res;
          }

          if (config.xType === "timeseries")
            // See https://d3js.org/d3-time-format#locale_format
            title = timeFormat(
              config.tooltip?.xFormat ?? "%b %d, %Y, %H:%M:%S"
            )(d[0]?.x);
          else title = String(d[0]?.x);

          const lines = d
            .filter(
              (item) =>
                item.value !== null &&
                !config.series?.find((v) => v.label === item.id)
                  ?.hideFromTooltip
            )
            .map((item) => {
              let value;
              if (Array.isArray(item.value)) {
                const values = item.value.sort();
                value = `${values[1].toFixed(precision)} [${values[0].toFixed(precision)}; ${values[2].toFixed(precision)}]`;
              } else value = item.value?.toFixed(precision);

              return {
                title: item.name ?? item.id,
                value,
                color: color(item),
              };
            });

          return renderToStaticMarkup(
            <Tooltip title={title} subtitle={subtitle} lines={lines} />
          );
        },
      },
    }),
    [onElementSelected, config, data, getValue]
  );

  return (
    <>
      <style>
        {config.series?.flatMap((t) => {
          const res: string[] = [];

          if (t.color)
            res.push(
              `.bb-area-${t.label.replaceAll(" ", "-")} {stroke: ${t.color}!important;}`
            );

          if (typeof t.lineWidth !== "undefined")
            res.push(
              `.bb-area-${t.label.replaceAll(" ", "-")}, .bb-line-${t.label.replaceAll(" ", "-")} {stroke-width: ${t.lineWidth}px!important;}`
            );

          return res;
        })}
      </style>
      <BillboardJS
        bb={bb}
        options={options}
        ref={ref}
        className="bb h-full w-full"
      />
    </>
  );
}
